.cardContainer {
    max-width: 800px;
    margin: 0 auto;
}
.annSection {
    font-weight: 800;
}

.textCount {
    font-weight: 700;
    font-size: 0.8em;
}
.google-places-autocomplete {
    width: 100%;
}
.addressBox {
    border-width: 0 !important;
    outline: none;
    height: 32px;
    width: 100%;
    padding: 8px 10px 8px 0;
    color: #000
}
.addressBox:focus {outline: none;}

.maxSix {
    max-width: 600px;
}